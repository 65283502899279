<mat-drawer-container
  class="pannel-container sidebar invoice-activity-log unset"
  [hasBackdrop]="true"
  ><mat-drawer
    #drawer
    mode="side"
    position="end"
    [opened]="isOpen"
    (openedChange)="isOpen = $event"
  >
    <div class="modal-header d-flex justify-between mb-6">
      <div>
        <h2 class="mat-subtitle-1 mb-0 grey-text">Activity Log</h2>
      </div>
      <mat-icon class="pointer" (click)="drawer.toggle()">close</mat-icon>
    </div>

    <ng-container *ngIf="activityLogsGrouped.length > 0">
      <ng-container *ngFor="let group of activityLogsGrouped">
        <div class="content-wrapper mb-1">
          <div class="content-header mb-1">
            <span class="textXS text">{{ formatDate(group.date) }}</span>
          </div>
        </div>
        <table
          mat-table
          [dataSource]="group.logs"
          matSort
          class="custom-action-table"
        >
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td
              mat-cell
              *matCellDef="let log"
              class="border-bottom-unset name-col"
            >
              <div class="activity-info align-center d-flex mr-8">
                <div class="avatar-smX mr-2">
                  <div
                    style="
                      background-color: #8f8fc3;
                      color: #fff;
                      border-radius: 50%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    {{ getDefaultPhotoText(log.user.name) }}
                  </div>
                </div>
                <span class="grey-text textXS name-info">{{
                  log.user.name
                }}</span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <td
              mat-cell
              *matCellDef="let log"
              class="grey-text border-bottom-unset textXS date-col"
            >
              {{ log.timestamp | date : "shortTime" : "UTC" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="account">
            <th mat-header-cell *matHeaderCellDef>Account</th>
            <td
              mat-cell
              *matCellDef="let log"
              class="grey-text border-bottom-unset textXS account-col name-info"
            >
              {{ getActivityTypeDisplayText(log.activityType) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="creation">
            <th mat-header-cell *matHeaderCellDef>Creation</th>
            <td
              mat-cell
              *matCellDef="let log"
              class="grey-text border-bottom-unset textXS name-info"
            >
              {{ log.activityDetail }}
            </td>
          </ng-container>

          <tr
            mat-row
            class="action-table-body-data"
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
        </table>
      </ng-container>
    </ng-container>
  </mat-drawer>
</mat-drawer-container>
